import { Fab } from "@material-ui/core";
import React from "react";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

interface UpDirectoryButtonProps {
    parentDirHref: string;
}

export const UpDirectoryButton: React.FC<UpDirectoryButtonProps> = (
    props: UpDirectoryButtonProps
) => {
    const parentDirHref = props.parentDirHref;
    const style = {
        margin: 0,
        right: 20,
        bottom: 20,
        left: "auto",
        position: "fixed",
    } as React.CSSProperties;
    return (
        <Fab
            style={style}
            onClick={() => (window.location.href = parentDirHref)}
        >
            <KeyboardArrowLeftIcon />
        </Fab>
    );
};
