import { AppBar, Toolbar, Typography } from "@material-ui/core";
import React from "react";

interface TopBarProps {
    listingsDir: string;
}

export const TopBar = (props: TopBarProps) => {
    const listingsDir = props.listingsDir;
    return (
        <React.Fragment>
            <AppBar position="sticky" className="TopBar">
                <Toolbar>
                    <Typography variant="h6" style={{ flex: 1 }}>
                        Directory Listings for {listingsDir}
                    </Typography>
                    <Typography variant="subtitle1">💚 illemonati</Typography>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};
