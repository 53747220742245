import { Listings } from "./Listing";

export const isListingsPage = (): boolean => {
    const firstElement = document.body.firstElementChild;
    if (!firstElement) return false;
    if (!(firstElement.tagName.toLowerCase() === "h1")) return false;
    if (!firstElement.innerHTML.startsWith("Index of ")) return false;
    if (!window.location.href.endsWith("/")) return false;
    if (!document.title.startsWith("Index of ")) return false;
    return true;
};

export const getListings = (): Listings => {
    return new Listings();
};
