export interface Listing {
    title: string;
    href: string;
    timestamp: Date;
    size: number;
}

export class Listings {
    listingDir: string = "";
    parentDirHref: string = "";
    listings: Listing[] = [];
    constructor() {
        this.getListingDir();
        this.getListingsFromPage();
    }
    getListingDir() {
        const title = document.querySelector("h1");
        if (!title) return;
        const prefix = "Index of ";
        this.listingDir = title.innerText.substring(prefix.length);
    }
    getListingsFromPage() {
        this.listings = [];
        const preElement = document.querySelector("pre");
        if (!preElement) return;
        const links = Array.from(preElement.querySelectorAll("a"));
        this.parentDirHref = links[0].href;
        for (const link of links.slice(1)) {
            const title = link.innerText;
            const postfixText = link.nextSibling as Text;
            const postfixTextData = postfixText.data
                .trim()
                .split("  ")
                .filter((e) => e !== "");
            const timestamp = new Date(postfixTextData[0]);
            const size = parseInt(postfixTextData[1]);

            const listing = {
                title,
                href: link.href,
                timestamp,
                size,
            };
            this.listings.push(listing);
        }
    }
}
