import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { getListings, isListingsPage } from "./utils/listing-page";

const rootNode = document.getElementById("nginx-custom-directory-listing-root");

const removeBasicPage = () => {
    Array.from(document.body.children)
        .slice(0, 4)
        .forEach((e) => e.remove());
};

const main = () => {
    const listings = getListings();
    removeBasicPage();
    ReactDOM.render(
        <React.StrictMode>
            <App listings={listings} />
        </React.StrictMode>,
        rootNode
    );

    reportWebVitals();
};
isListingsPage() && main();
serviceWorkerRegistration.register();
document.body.style.display = "";
