import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React from "react";
import { Listings } from "../utils/Listing";
import prettyBytes from "pretty-bytes";
import moment from "moment";

interface ListingsTable {
    listings: Listings;
}

export const ListingsTable: React.FC<ListingsTable> = (
    props: ListingsTable
) => {
    const tableTitles = ["index", "listing", "size", "date modified"];
    const listings = props.listings;
    const handleClick = (href: string) => () => {
        window.location.href = href;
    };

    return (
        <div className="ListingsTable">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        {tableTitles.map((title) => (
                            <TableCell
                                style={{ textTransform: "capitalize" }}
                                key={title}
                            >
                                {title}
                            </TableCell>
                        ))}
                    </TableHead>
                    <TableBody>
                        {listings.listings.map((listing, i) => {
                            let listingSizeDisplay = "--";
                            try {
                                listingSizeDisplay = prettyBytes(listing.size);
                            } catch (e) {}

                            const cells = [
                                i,
                                listing.title,
                                listingSizeDisplay,
                                moment(listing.timestamp).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                ),
                            ];

                            return (
                                <TableRow
                                    key={i}
                                    hover
                                    onClick={handleClick(listing.href)}
                                >
                                    {cells.map((c, i) => (
                                        <TableCell key={i}>{c}</TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
