import {
    Box,
    Container,
    CssBaseline,
    Paper,
    Typography,
} from "@material-ui/core";
import React from "react";
import "./App.css";
import { ListingsTable } from "./components/ListingsTable";
import { TopBar } from "./components/TopBar";
import { UpDirectoryButton } from "./components/UpDirectoryButton";
import { Listings } from "./utils/Listing";

interface AppProps {
    listings: Listings;
}

const App: React.FC<AppProps> = (props: AppProps) => {
    const listings = props.listings;
    console.log(listings);
    const showUpDirectoryButton =
        listings.parentDirHref !== window.location.href;
    document.body.style.background = "#f5f5f5";
    return (
        <div className="nginx-custom-directory-listing-app">
            <CssBaseline />
            <TopBar listingsDir={listings.listingDir} />
            <Container>
                <Box m={2}>
                    <ListingsTable listings={listings} />
                </Box>
            </Container>
            {showUpDirectoryButton && (
                <UpDirectoryButton parentDirHref={listings.parentDirHref} />
            )}
        </div>
    );
};

export default App;
